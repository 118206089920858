import React from 'react';

type LabelProps = {
  children:JSX.Element,
  cssClass:string
};

const TimelineLabel: React.FC<LabelProps> = ({children,cssClass}) => {
  return (
        <span className="timeline-label">
          <span className={cssClass}>{children}</span>
        </span>
      );
}

export default TimelineLabel;
