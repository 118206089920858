import React from 'react';
import { Card, CardColumns } from 'react-bootstrap'
import { Link, graphql, useStaticQuery } from "gatsby"

import Divider from '../../components/Divider';
import Timeline from '../../components/Timeline';
import TimelineLabel from '../../components/Timeline/TimelineLabel';
import TimelineItem from '../../components/Timeline/TimelineItem';

import './style.css';

const Movimento: React.FC = () => {
  const data = useStaticQuery(query);
  console.log(data);
  return (
    <React.Fragment>
      <Divider text="TEIA EM MOVIMENTO"/>
      <div id="page-timeline">
        <Timeline>
          {data.allStrapiTimelines.group.map((item,key) => {
            return (
                <>
                  <TimelineLabel cssClass="label" key={key} >{item.edges[0].node.date}</TimelineLabel>
                  {item.edges.map((subItem,key)=>{
                    return(<TimelineItem midia={subItem.node.midia} slideshow="true" title={subItem.node.title} text={subItem.node.content} key={subItem.node.id}/>)
                    }
                  )}
                </>
              );
          })}
          {/*<TimelineLabel cssClass="label bg-primary"> CARREGAR MAIS </TimelineLabel>*/}
        </Timeline>
      </div>
    </React.Fragment>
  );
}


export default Movimento;

const query = graphql`
  query allStrapiTimelines {
    allStrapiTimelines { 
      group(field: date) {
        edges {
          node {
            id
            content
            date(formatString:"Y")
            title
            midia {
              id
              childImageSharp {
                fluid{
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }`