import React from 'react';
import { Carousel } from 'react-bootstrap';
import Img from 'gatsby-image';

export const MyCarousel = ({midia}) => {
    return (
    <Carousel>
      {midia.map( (currentProject,key) => {
        return(
          <Carousel.Item key={key} className="timeline-slideshow">
            <Img fluid={currentProject.childImageSharp.fluid} alt="Imagem da timeline contendo o trabalho realizado" className="d-block w-100 rounded"/>
          </Carousel.Item>)
        })
      }
    </Carousel>)
}

const TimelineItem: React.FC = ({title,text,midia,slideshow}) => {
  return (
    <React.Fragment>
      <div className="timeline-item">
        <div className="timeline-point timeline-point"></div>
        <div className="timeline-event">
          <div className="widget has-shadow rounded-widget">
            <div className="widget-header d-flex align-items-center">
              <div className="d-flex flex-column mr-auto">
                <div className="title">
                  <span className="timeline-item-title">{title}</span> 
                </div>
              </div>
            </div>
            <div className="widget-body">
              <div className="content-text container"  dangerouslySetInnerHTML= {{ __html: text}}>
              </div>
              <div className="d-flex flex-row">
                <div className="container h-100 w-100">
                  {slideshow &&
                    <MyCarousel midia={midia} />
                  }
                </div>
              </div>
            </div>

            <div className="widget-footer d-flex align-items-center">
              <div className="col no-padding d-flex justify-content-end">
                  
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default TimelineItem;