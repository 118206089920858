import React from 'react';
import {
  Box,
  Card,
  Image,
  Heading,
  Text
} from 'rebass'

const Timeline: React.FC = ({children}) => {
    return (
            <Box className="row justify-content-center">
              <Box className="col-xl-10 col-12">
                <Box className="timeline timeline-line-solid">
                  {children}
                </Box>
              </Box>
            </Box>
    );
}

export default Timeline;
